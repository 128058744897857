import React, {useState} from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Companies} from './companies/Companies'
import {Jobs} from './jobs/Jobs'
import {ProfileHeader} from './ProfileHeader'

export interface IJobsStatistics {
  jobsCount: number
  jobsApproved: number
  jobsPending: number
  jobsRemote: number
  jobsLocal: number
}

export const JobsStatisticsContext = React.createContext<any>(null!)

const userBreadCrumbs: Array<PageLink> = [
  {
    title: 'Profile',
    path: '/user/profile/jobs',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ProfilePage: React.FC = () => {
  const [jobStatistics, setJobStatistics] = useState<IJobsStatistics>({
    jobsCount: 0,
    jobsApproved: 0,
    jobsPending: 0,
    jobsRemote: 0,
    jobsLocal: 0,
  })
  return (
    <JobsStatisticsContext.Provider value={{jobStatistics, setJobStatistics}}>
      <Routes>
        <Route
          element={
            <>
              <ProfileHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='companies'
            element={
              <>
                <PageTitle breadcrumbs={userBreadCrumbs}>Compañias</PageTitle>
                <Companies />
              </>
            }
          />
          <Route
            path='jobs'
            element={
              <>
                <PageTitle breadcrumbs={userBreadCrumbs}>Vacantes</PageTitle>
                <Jobs />
              </>
            }
          />
          <Route index element={<Navigate to='/user/profile/jobs' />} />
        </Route>
      </Routes>
    </JobsStatisticsContext.Provider>
  )
}

export default ProfilePage
