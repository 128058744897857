import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FilterDropdown } from './FilterDropdown'
import { KTSVG } from '_metronic/helpers'
import { Pagination } from './Pagination'
import { useFormik } from 'formik'

export interface IPagination {
    totalRecords: number
    currentPage: number
}

interface Props {
    title: string,
    children: React.ReactNode
    pagintation?: IPagination,
    search?: (model: any) => void,
    setIsModalCreateVisible?: (isVisible: boolean) => void,
}

export const Page: React.FC<Props> = ({ title, pagintation, setIsModalCreateVisible, search, children }) => {
    const [keyword, setKeyword] = React.useState('')
    const [isActive, setIsActive] = React.useState<any>(undefined)

    React.useEffect(() => {
        if (search) {
            search({ keyword, isActive, page: pagintation?.currentPage });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagintation?.currentPage])

    const initialValues = {
        keyword: '',
        isActive: 'all',
    }

    const handlerChangePage = (page: number) => {
        if (search) {
            search({ keyword, isActive, page });
        }
    }

    const form = useFormik({
        initialValues,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setIsActive(values.isActive === 'all' ? undefined : values.isActive === 'yes' ? true : false)
            setKeyword(values.keyword)
            if (search) {
                search({ keyword, isActive, page: pagintation?.currentPage });
            }
        },
    })
    return (
        <>
            <div className='content flex-row-fluid'>
                <div className='row'>
                    <div className={`card card-xl-stretch mb-xl-8`}>
                        <div className='card-header border-0 pt-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{title}</span>
                            </h3>
                            <div className='card-toolbar'>
                                {setIsModalCreateVisible && <a href='#' onClick={() => setIsModalCreateVisible(true)} className='btn btn-light'>
                                    Nuevo
                                </a>}
                                {search && (<>
                                    <OverlayTrigger
                                        delay={{ hide: 450, show: 300 }}
                                        overlay={(props) => <Tooltip {...props}>Filtros</Tooltip>}
                                        placement='bottom'
                                    >
                                        <button
                                            type='button'
                                            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary ms-5'
                                            data-kt-menu-trigger='click'
                                            data-kt-menu-placement='bottom-end'
                                            data-kt-menu-flip='top-end'
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                                        </button>
                                    </OverlayTrigger>
                                    <FilterDropdown>
                                        <form onSubmit={form.handleSubmit} autoComplete='off'>
                                            <div className='mb-10'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='Palabra clave'
                                                    {...form.getFieldProps('keyword')}
                                                />
                                            </div>
                                            <div className='mb-10'>
                                                <label className='form-label fw-bold'>Esta Activa:</label>
                                                <div className='d-flex  justify-content-between'>
                                                    <div className='form-check  form-check-custom form-check-solid'>
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            {...form.getFieldProps('isActive')}
                                                            value={'all'}
                                                            defaultChecked={true}
                                                        />
                                                        <label className='form-check-label'>Todas</label>
                                                    </div>
                                                    <div className='form-check  form-check-custom form-check-solid'>
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            {...form.getFieldProps('isActive')}
                                                            value={'yes'}
                                                        />
                                                        <label className='form-check-label'>Si</label>
                                                    </div>
                                                    <div className='form-check  form-check-custom form-check-solid'>
                                                        <input
                                                            className='form-check-input'
                                                            type='radio'
                                                            {...form.getFieldProps('isActive')}
                                                            value={'no'}
                                                        />
                                                        <label className='form-check-label'>No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type='reset'
                                                    onClick={() => {
                                                        form.resetForm()
                                                        setKeyword('')
                                                        setIsActive(undefined)
                                                    }}
                                                    className='btn btn-sm btn-white btn-active-light-primary me-2'
                                                    data-kt-menu-dismiss='true'
                                                >
                                                    Limpiar
                                                </button>

                                                <button
                                                    type='submit'
                                                    className='btn btn-sm btn-primary'
                                                    data-kt-menu-dismiss='true'
                                                >
                                                    Aplicar
                                                </button>
                                            </div>
                                        </form>
                                    </FilterDropdown>
                                </>)}
                            </div>
                        </div>
                        <div className='card-body py-3'>
                            {children}
                        </div>
                        {pagintation && <Pagination
                            pageSize={10}
                            totalRecorsds={pagintation.totalRecords}
                            currentPage={pagintation.currentPage}
                            onPageChange={handlerChangePage}
                        />}
                    </div>
                </div>
            </div>
        </>
    )
}
