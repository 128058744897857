import { HIDE_JOB_MODAL, SHOW_JOB_MODAL } from "./actionTypes";

interface ShowModalAction {
    type: typeof SHOW_JOB_MODAL;
}

interface HideModalAction {
    type: typeof HIDE_JOB_MODAL;
}

export type ModalActionTypes = ShowModalAction | HideModalAction;

export const showModal = (): ShowModalAction => ({
    type: SHOW_JOB_MODAL,
});

export const hideModal = (): HideModalAction => ({
    type: HIDE_JOB_MODAL,
});