import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import {
  LocationsControllerServiceProxy,
  UpdateLocationDto,
} from 'app/services/service-proxies/service-proxies'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Button, Modal} from 'antd'

const categoryClient = new LocationsControllerServiceProxy(undefined, axios)

type Props = {
  location: any | undefined
  initialValues: any
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onEditLocation: () => void
}

const registrationSchema = Yup.object().shape({
  _id: Yup.string().required('El id requerido'),
  name: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(50, 'Maximo 50 caracteres')
    .required('El primer nombre es requerido'),
  isActive: Yup.boolean().required('El estado es requerido'),
})

export const EditLocation: FC<Props> = ({
  location,
  initialValues,
  isModalVisible,
  setIsModalVisible,
  onEditLocation,
}) => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setIsModalVisible(isModalVisible)
    if (isModalVisible) {
      formik.setValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible])

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      let data: UpdateLocationDto = new UpdateLocationDto()
      data.init(values)
      data.name = values.name.toUpperCase()
      categoryClient
        .update(data._id, data)
        .then(() => {
          setLoading(false)
          setIsModalVisible(false)
          onEditLocation()
        })
        .catch((err: any) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(err.message)
        })
    },
  })
  return (
    <Modal
      title={`Editar categoria ${location?.name}`}
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      okText='Editar'
      cancelText='Cancelar'
      destroyOnClose={true}
      footer={[
        <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
          Editar Categoria
        </Button>,
      ]}
    >
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        {/* begin::Form group name */}
        <div className='fv-row mb-7'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>Nombre</label>
          <input
            placeholder='Nombre'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.name && formik.errors.name,
              },
              {
                'is-valid': formik.touched.name && !formik.errors.name,
              }
            )}
          />
          {formik.touched.name && formik.errors.name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.name}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-7'>
          {/* begin::Form group lastName */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Activo</label>
            <div className='mb-10'>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  {...formik.getFieldProps('isActive')}
                  className={clsx(
                    'form-check-input',
                    {
                      'is-invalid': formik.touched.name && formik.errors.name,
                    },
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  type='checkbox'
                  checked={formik.values?.isActive}
                />
              </div>
            </div>
            {formik.touched.isActive && formik.errors.isActive && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.isActive}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
        {/* end::Form group */}
      </form>
    </Modal>
  )
}
