/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState, useCallback} from 'react'
import {Field, ErrorMessage, useField} from 'formik'
import {Image} from 'antd'
import ReactQuill from 'react-quill'
import {useDropzone} from 'react-dropzone'
import 'react-quill/dist/quill.snow.css'
import {fileToBase64, niceBytes} from '../../../../shared/utils/utils'

const modules = {
  toolbar: [
    [{header: 1}, {header: 2}], // custom button values
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{font: []}],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],
    [{list: 'ordered'}, {list: 'bullet'}],
    [{script: 'sub'}, {script: 'super'}], // superscript/subscript
    [{indent: '-1'}, {indent: '+1'}], // outdent/indent
    ['clean'],
  ],
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
]

const JobStep2: FC = () => {
  const [convertedText, setConvertedText] = useState('')
  const [field, meta, helpers] = useField('description')
  const [fieldOnlyImage] = useField('onlyImage')
  const [fieldImage, metaImage, helpersImage] = useField('image')

  const onDrop = useCallback(
    async (files) => {
      const base64 = await fileToBase64(files[0])
      helpersImage.setValue(base64)
    },
    [helpersImage]
  )

  const remove = (file: any) => {
    helpersImage.setValue('')
    acceptedFiles.splice(file, 1)
  }

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop,
    maxSize: 10000000,
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
      'image/jpg': ['.jpg'],
    },
  })

  useEffect(() => {
    if (field.value?.length > 0) {
      setConvertedText(field.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (value: any) => {
    const html = value
    setConvertedText(html)
    if (value === '<p><br></p>') {
      helpers.setValue('')
      return
    }
    helpers.setValue(html)
  }

  const files = acceptedFiles.map((file: any) => (
    <div className='card bg-warning bg-opacity-25' key={file.path}>
      <div className='card-body d-flex justify-content-between'>
        <span>
          {file.path} - {niceBytes(file.size)} bytes
        </span>
        <span onClick={() => remove(file)}>
          <i
            className='fa fa-times-circle text-danger fs-3'
            style={{cursor: 'pointer'}}
            aria-hidden='true'
          ></i>
        </span>
      </div>
    </div>
  ))

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Informacion de la vacante</h2>
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3 required'>
          Titulo
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='¿Qué estás buscando?'
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='title'
          placeholder='Titulo de la vacante'
        />
        <ErrorMessage name='title'>{(msg) => <div style={{color: 'red'}}>{msg}</div>}</ErrorMessage>
      </div>

      <div className='mb-10 fv-row'>
        <div className='form-check form-switch form-check-custom form-check-solid'>
          <Field type='checkbox' className='form-check-input' name='onlyImage' />
          <label className='form-check-label' htmlFor='flexSwitchDefault'>
            Publicar una imagen
          </label>
        </div>
      </div>

      {!fieldOnlyImage.value && (
        <div className='mb-0 fv-row'>
          <label className='d-flex align-items-center form-label mb-5 required'>
            Descripcion de la vacante
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Tip: Se lo más explícito posible, si eres de recursos humanos consulta con un experto en el area sobre cuales son los requerimientos de la posicion.'
            ></i>
          </label>

          <div className='mb-0'>
            <ErrorMessage name='description'>
              {(msg) => <div style={{color: 'red'}}>{msg}</div>}
            </ErrorMessage>
            <ReactQuill
              theme='snow'
              value={convertedText}
              onChange={handleChange}
              style={{minHeight: '300px', height: '5rem'}}
              defaultValue={field.value}
              modules={modules}
              formats={formats}
            />
          </div>
        </div>
      )}

      {fieldOnlyImage.value && (
        <div className='fv-row mb-0'>
          <label className='class="form-label fw-bolder text-dark fs-6 required'>
            Imagen de la vacante (.jpg, .png)
          </label>
          <section className='mt-2'>
            {acceptedFiles.length === 0 ? (
              <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <p>Arrastre y suelte la imagen aquí, o haga clic para seleccionar la imagen.</p>
              </div>
            ) : (
              <aside>
                <div>{files}</div>
              </aside>
            )}
          </section>
          <ErrorMessage name='image'>
            {(msg) => <div style={{color: 'red'}}>{msg}</div>}
          </ErrorMessage>
          <div style={{width: '50%', margin: 'auto', marginTop: '1rem'}}>
            <Image width={500} src={fieldImage.value} />
          </div>
        </div>
      )}
    </div>
  )
}

export {JobStep2}
