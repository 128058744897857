/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect, useState} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage} from '../modules/auth'
import {RootState} from '../../setup'
import {App} from '../App'
import {Unsubscribe} from '_metronic/layout/components/Unsubscribe'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [resetPassword, setResetPassword] = useState<boolean>(false)
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const currentURL = window.location.href
    setResetPassword(currentURL.includes('reset-password'))
    if (resetPassword) {
      const tokenSegment = currentURL.split('reset-password')[1]
      setToken(tokenSegment)
    }
  }, [])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {resetPassword ? (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to={`/auth/reset-password${token}`} />} />
            </>
          ) : (
            <>
              <Route path='error/*' element={<ErrorsPage />} />
              <Route path='unsubscribe/:id' element={<Unsubscribe />} />
              <Route path='logout' element={<Logout />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='/*' element={<PrivateRoutes />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
