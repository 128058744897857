import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
// import {Toolbar} from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import {
  DrawerMessenger,
  ActivityDrawer,
} from '../partials'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { Bot } from 'app/shared/components/ChatBot/Bot'

const MasterLayout: React.FC = ({ children }) => {
  const { classes } = useLayout()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid position-relative'>
        <div className='wrapper d-flex flex-column flex-row-fluid position-relative' id='kt_wrapper'>
          <HeaderWrapper />

          {/* <div className='position-relative' style={{top: '350px', left: '3rem'}}>
            <h1>sdfa</h1>
            <Adsense
              client='ca-pub-3433429465522786'
              slot='1187399130'
              layout='in-article'
              format='fluid'
            />
          </div> */}
          <div
            id='kt_content'
            className='content d-flex flex-column flex-column-fluid'
            style={{ minHeight: '700px' }}
          >
            {/* <Toolbar /> */}
            <div
              className={clsx(
                'd-flex flex-column-fluid align-items-start',
                classes.contentContainer.join(' ')
              )}
              id='kt_post'
            >
              <AsideDefault />
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Bot />
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
