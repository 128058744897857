import {Suspense, useCallback} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import {CreateJob} from 'app/pages/profile/jobs/CreateJob'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from 'setup'
import {hideModal} from './modules/jobs/actions'

const App = () => {
  const showModalState = useSelector<RootState>(({job}) => job.showModal, shallowEqual) as boolean
  const dispatch = useDispatch()

  const handleHideModal = useCallback(() => {
    dispatch(hideModal())
  }, [dispatch])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            <CreateJob isModalVisible={showModalState} setIsModalVisible={handleHideModal} />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
