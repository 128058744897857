import {notification} from 'antd'
import {actionTypes} from 'app/modules/auth'

export default function setupAxios(axios: any, store: any) {
  const {dispatch} = store
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      document.body.classList.add('loading-indicator')
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => {
      document.body.classList.remove('loading-indicator')
      notification['error']({
        message: err.message.error || 'Notificatcion de error',
        description: err.message.message,
      })
      Promise.reject(err)
    }
  )

  axios.interceptors.response.use(
    function (response: any) {
      // spinning hide
      // UPDATE: Add this code to hide global loading indicator
      document.body.classList.remove('loading-indicator')

      return response
    },
    function (err: any) {
      document.body.classList.remove('loading-indicator')
      const message = err.response?.data?.message
      const status = err.response?.status
      switch (status) {
        case 400:
          notification['error']({
            message: 'Notificatcion de error',
            description: message,
          })
          break
        case 401:
          notification['error']({
            message: 'Notificatcion de error',
            description: 'Su sesión ha expirado o no esta autorizado',
          })
          dispatch({type: actionTypes.Logout})
          window.location.href = '/'
          break
        case 403:
          notification['error']({
            message: 'Notificatcion de error',
            description: 'No tiene permisos para realizar esta acción',
          })
          break
        case 404:
          notification['error']({
            message: 'Notificatcion de error',
            description: 'No se encontró el recurso',
          })
          break
        case 422:
          notification['error']({
            message: 'Notificatcion de error',
            description: 'Error de validación',
          })
          break
        case 500:
          notification['error']({
            message: message || 'Notificatcion de error',
            description: message || 'Error interno del servidor',
          })
          break
        default:
          notification['error']({
            message: 'Notificatcion de error',
            description: 'Error interno del servidor',
          })
          break
      }

      return Promise.reject(err)
    }
  )
}
