import React from 'react'
import { IPagination, Page } from '../../shared/components/Page'
import { CreateLink } from './CreateLink'
import { LinkControllerServiceProxy } from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { enumToText, formatDate } from 'app/shared/utils/utils'
import { LinkType } from 'app/shared/constants/linkTypes'

const linkClient = new LinkControllerServiceProxy(undefined, axios)

interface IFilters {
    keyword: string,
    isActive: boolean | undefined,
    page: number
}

export const LinkPage = () => {
    const [isModalCreateVisible, setIsModalCreateVisible] = React.useState(false)
    const [links, setLinks] = React.useState<any>([])
    const [currentUpdate, setCurrentUpdate] = React.useState<any>(null)
    const [pagination, setPagination] = React.useState<IPagination>({
        totalRecords: 0,
        currentPage: 1,
    })

    React.useEffect(() => {
        handlerSearch({ keyword: '', isActive: true, page: 1 })
    }, [])

    const handlerSearch = async (model: IFilters) => {
        const result: any = await linkClient.findAll(model.isActive, model.keyword, 10, model.page)
            .catch((err) => {
                console.log(err)
            })
        if (result) {
            result.data.Items.forEach((user: any) => (user.created_at = new Date(user.created_at)))
            setLinks(result.data.Items)
            setPagination({
                totalRecords: result.TotalCount,
                currentPage: model.page
            })
        }
    }
    const handlerEdit = (link: any) => {
        setCurrentUpdate(link);
        setIsModalCreateVisible(true)
    }

    const handlerDelete = async (link: any) => {
        const data: any = await linkClient.delete(link._id).catch((err: any) => {
            console.log(err)
        });
        if (data) {
            handlerSearch({ keyword: '', isActive: true, page: 1 })
        }
    }

    return (
        <div>
            <Page title='Listado de enlaces' setIsModalCreateVisible={setIsModalCreateVisible} search={handlerSearch} pagintation={pagination}>
                <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-5'>
                        <thead>
                            <tr className='fw-bolder fs-6 border-bottom border-gray-200 text-gray-800'>
                                <th>Nombre</th>
                                <th>Icono</th>
                                <th>Tipo</th>
                                <th>Enlace</th>
                                <th>Fecha de creacion</th>
                                <th style={{ textAlign: 'center' }}>Activa</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {links &&
                                links.map((link: any) => (
                                    <tr key={link._id}>
                                        <td>{link.name}</td>
                                        <td>{link.icon ? <i className={link.icon}></i> : '-'}</td>
                                        <td>{enumToText(LinkType, link.linkType)}</td>
                                        <td>{link.url}</td>
                                        <td>{formatDate(link.created_at)}</td>
                                        <td align='center'>
                                            <OverlayTrigger
                                                delay={{ hide: 450, show: 300 }}
                                                overlay={(props) => (
                                                    <Tooltip {...props}>
                                                        {link.isActive ? 'Activa' : 'Inactiva'}
                                                    </Tooltip>
                                                )}
                                                placement='bottom'
                                            >
                                                {link.isActive ? (
                                                    <i
                                                        style={{ fontSize: '1.4rem' }}
                                                        className='fas fa-check-square text-success'
                                                    ></i>
                                                ) : (
                                                    <i
                                                        style={{ fontSize: '1.4rem' }}
                                                        className='fa fa-minus-square text-danger'
                                                    ></i>
                                                )}
                                            </OverlayTrigger>
                                        </td>
                                        <td className='float-end'>
                                            <Dropdown>
                                                <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                                                    Opciones
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handlerEdit(link)}>
                                                        <i className='fas fa-pen-alt me-2'></i> Editar
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handlerDelete(link)}>
                                                        <i className='fas fa-trash-alt me-2'></i> Eliminar
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </Page>
            <CreateLink isVisible={isModalCreateVisible} setIsVisible={setIsModalCreateVisible}
                onCreate={() => handlerSearch({ keyword: '', isActive: true, page: 1 })}
                updateLink={currentUpdate} />
        </div>
    )
}
