import React from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {JobPage} from './JobPage'
import {ViewJob} from './ViewJob'

const JobLayout: React.FC = () => {
  return (
    <div className='content flex-row-fluid'>
      <div className='row'>
        <Outlet />
      </div>
    </div>
  )
}

export const JobWrapper: React.FC = () => {
  return (
    <Routes>
      <Route element={<JobLayout />}>
        <Route path='/' element={<JobPage />} />
        <Route path='/search' element={<JobPage />} />
        <Route path='/:slug' element={<ViewJob />} />
      </Route>
    </Routes>
  )
}
