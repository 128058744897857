import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {UserPage} from '../pages/users/UserPage'
import {CategoryPage} from 'app/pages/categories/CategoryPage'
import {LocationPage} from 'app/pages/locations/LocationPage'
import {JobsManagement} from 'app/pages/jobs/JobsManagement'
import {FaqsManagement} from 'app/pages/faqs/FaqsManagement'
import {BlogWrapper} from 'app/pages/blog/BlogWrapper'
import {ServicesPage} from 'app/pages/services/ServicesPage'
import ProfilePage from 'app/pages/profile/ProfilePage'
import {LinkWrapper} from 'app/pages/links/LinksWrapper'
import {JobWrapper} from 'app/pages/jobs/JobWrapper'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/' />} />
        <Route path='/' element={<DashboardWrapper />} />
        <Route path='/admin/users' element={<UserPage />} />
        <Route path='/admin/categories' element={<CategoryPage />} />
        <Route path='/admin/locations' element={<LocationPage />} />
        <Route path='/admin/jobManagement' element={<JobsManagement />} />
        <Route path='/admin/links/*' element={<LinkWrapper />} />
        <Route path='/preguntas-frecuentes' element={<FaqsManagement />} />
        <Route path='consejos-busqueda-empleo/*' element={<BlogWrapper />} />
        <Route path='ofertas-de-empleo/*' element={<JobWrapper />} />
        <Route path='services/*' element={<ServicesPage />} />
        <Route path='user/profile/*' element={<ProfilePage />} />
        <Route path='*' element={<Navigate to='/' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
