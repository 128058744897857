import React from 'react'
import Slider from 'react-slick'
import './VideoSlider.scss'

interface Props {
  playlist: any[]
}

export const VideoSlider: React.FC<Props> = ({playlist}) => {
  const PHONE_TABLE_SIZE = '(min-width: 769px)'

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: playlist.length === 1 ? 1 : playlist.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    className: 'center',
    centerMode: true,
    centerPadding: '60px',
  }

  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  const handleResize = () => {
    const mq = window.matchMedia(PHONE_TABLE_SIZE)
    if (mq.matches) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }
  }

  return (
    <>
      {!isMobile ? (
        <Slider {...settings}>
          {playlist.length > 0 &&
            playlist.map(({url, _id}) => (
              <div key={_id} className='mr-1'>
                <div className='embed-responsive embed-responsive-16by9 me-3'>
                  <iframe className='embed-responsive-item' src={url} allowFullScreen></iframe>
                </div>
              </div>
            ))}
        </Slider>
      ) : (
        <>
          {playlist.length > 0 && (
            <div className='d-flex mobile-video-slider'>
              {playlist.map(({url, _id}) => (
                <div key={_id} className='mr-1'>
                  <div className='embed-responsive embed-responsive-16by9 mr-2'>
                    <iframe className='embed-responsive-item' src={url} allowFullScreen></iframe>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  )
}
