/* eslint-disable jsx-a11y/anchor-is-valid */
import {UserModel} from 'app/modules/auth/models/UserModel'
import {CompanyControllerServiceProxy} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import {Image} from 'antd'
import React, {useEffect, useState} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from 'setup'
import {APP_COMPANY_DEFAULT_LOGO} from 'setup/app-constants'
import {CreateCompany} from './CreateCompany'
import {EditCompany} from './EditCompany'
import {Modal} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'

export const Companies: React.FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const companyClient = new CompanyControllerServiceProxy(undefined, axios)
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false)
  const [companies, setCompanies] = useState<any[]>([])
  const [selectedCompany, setSelectedCompany] = useState<any>()
  const [selectedFormInitialValues, setSelectedFormInitialValues] = useState<any>({})
  const [isModalVisible, setIsModalVisible] = useState(false)
  const {confirm} = Modal

  const getCompanies = async () => {
    const id = user._id
    companyClient.getAllWithoutPagination(id).then((res) => {
      res.data.forEach((user: any) => (user.created_at = new Date(user.created_at)))
      setCompanies(res.data)
    })
  }

  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onEditLocations = () => {
    getCompanies()
  }

  const showModelEditCompany = (company: any) => {
    setIsModalVisible(true)
    setSelectedCompany(company)
    setSelectedFormInitialValues({
      _id: company._id,
      name: company.name,
      isActive: company.isActive,
      url: company.url,
      email: company.email,
      logoUrl: company.logoUrl ? company.logoUrl : '',
    })
  }

  const handleDelete = (category: any) => {
    confirm({
      title: '¿Quieres eliminar esta compañía?',
      icon: <ExclamationCircleOutlined />,
      content: 'Una vez eliminada la compañía no podrá ser recuperada',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        companyClient.delete(category._id).then(() => {
          getCompanies()
        })
      },
    })
  }

  return (
    <>
      <div className='card card-custom card-flush'>
        <div className='card-header'>
          <h3 className='card-title'>Listado de compañías</h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-light'
              onClick={() => setIsModalCreateVisible(true)}
            >
              Nueva
            </button>
          </div>
        </div>
        <div className='card-body py-5'>
          {/* begin::Item */}
          <div>
            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='border-0'>
                  <th className='p-0'></th>
                  <th className='p-0'></th>
                  <th className='p-0'></th>
                  <th className='p-0'></th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody className='overflow-scroll'>
                {companies.map((company: any) => {
                  return (
                    <tr key={company._id}>
                      <td className='text-center text-muted fw-bold'>
                        <OverlayTrigger
                          delay={{hide: 450, show: 300}}
                          overlay={(props) => (
                            <Tooltip {...props}>{company.isActive ? 'Activa' : 'Inactiva'}</Tooltip>
                          )}
                          placement='bottom'
                        >
                          {company.isActive ? (
                            <i
                              style={{fontSize: '1.4rem'}}
                              className='fas fa-check-square text-success'
                            ></i>
                          ) : (
                            <i
                              style={{fontSize: '1.4rem'}}
                              className='fa fa-minus-square text-danger'
                            ></i>
                          )}
                        </OverlayTrigger>
                      </td>
                      <td style={{width: '1rem'}}>
                        <div className='symbol'>
                          <span className='symbol-label'>
                            <Image
                              width={100}
                              src={company.logoUrl ? company.logoUrl : APP_COMPANY_DEFAULT_LOGO.url}
                            />
                          </span>
                        </div>
                      </td>
                      <td>
                        <a className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                          {company.name}
                        </a>
                        <span className='text-muted fw-bold d-block'>{company.email}</span>
                      </td>
                      {!company.default && (
                        <td className='text-end'>
                          <Dropdown>
                            <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                              Opciones
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => showModelEditCompany(company)}>
                                <i className='fas fa-pen-alt me-2'></i> Editar
                              </Dropdown.Item>
                              <Dropdown.Item onClick={() => handleDelete(company)}>
                                <i className='fas fa-trash-alt me-2'></i> Eliminar
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      )}
                    </tr>
                  )
                })}
              </tbody>
              {/* end::Table body */}
            </table>
          </div>

          {/* end::Item */}
        </div>
      </div>
      <CreateCompany
        isModalVisible={isModalCreateVisible}
        onCreateLocation={onEditLocations}
        setIsModalVisible={setIsModalCreateVisible}
      />
      <EditCompany
        company={selectedCompany}
        initialValues={selectedFormInitialValues}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        onEditCompany={onEditLocations}
      />
    </>
  )
}
