import React from 'react';
import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from './BotAvatar';

const botName = 'Tu Empleo Ideal Bot';

const config: any = {
    initialMessages: [createChatBotMessage(`Hola! Yo soy ${botName}, ¿En qué puedo ayudarte con tu búsqueda de empleo?`, {})],
    botName: botName,
    customStyles: {
        botMessageBox: {
            backgroundColor: '#376B7E',
        },
        chatButton: {
            backgroundColor: '#5ccc9d',
        },
    },
    customComponents: {
        botAvatar: () => <BotAvatar />
    },
};

export default config;