/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { shallowEqual, useSelector } from 'react-redux'
import { Empty } from 'antd'
import { FaqsControllerServiceProxy } from 'app/services/service-proxies/service-proxies'
import { CreateFaq } from './CreateFaq'
import { FaqRow } from './FaqRow'
import { RootState } from 'setup'
import { UserModel } from 'app/modules/auth/models/UserModel'
import { APP_ROLES } from 'setup/app-constants'
import { EditFaq } from './EditFaq'

export const FaqsManagement: React.FC = () => {
  const isAuthorized = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const faqsClient = new FaqsControllerServiceProxy(undefined, axios)
  const [faqs, setFaqs] = useState<any[]>([])
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false)
  const [isModalEditVisible, setIsModalEditVisible] = useState(false)
  const [query, setQuery] = useState('')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isActive, setIsActive] = useState<any>(true)
  const [editModel, setEditModel] = useState<any>({})

  const getFaqs = async () => {
    faqsClient.findAll(isActive).then((res) => {
      res.data.forEach((user: any) => (user.created_at = new Date(user.created_at)))
      setFaqs(res.data)
    })
  }

  const handleEdit = (model: any) => {
    setEditModel({
      _id: model._id,
      question: model.question,
      answer: model.answer,
    })
    setIsModalEditVisible(true)
  }

  useEffect(() => {
    getFaqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  return (
    <>
      <div className='content flex-row-fluid'>
        <div className='row'>
          <div className={`card card-xl-stretch mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  Listado de Preguntas Frecuentes
                </span>
              </h3>
              <div className='card-toolbar'>
                {isAuthorized?.roles?.includes(APP_ROLES.ADMIN) && (
                  <a onClick={() => setIsModalCreateVisible(true)} className='btn btn-light'>
                    Nuevo
                  </a>
                )}
              </div>
            </div>
            <div className='card-body py-3'>
              <div className='fv-row mb-7'>
                <input
                  placeholder='Buscar...'
                  type='search'
                  autoComplete='off'
                  className='form-control form-control-lg position-sticky'
                  value={query}
                  onChange={(event) => setQuery(event.target.value)}
                />
              </div>
              {faqs.length === 0 && <Empty description='No hay preguntas frecuentes' />}
              <div className='overflow-auto' style={{ height: '800px' }}>
                {faqs.length > 0 &&
                  faqs
                    .filter((question) => {
                      if (query === '') {
                        return question
                      } else if (question.question.toLowerCase().includes(query.toLowerCase())) {
                        return question
                      }
                    })
                    .map((faq: any, idx: number) => (
                      <FaqRow
                        key={faq._id}
                        id={faq._id}
                        question={faq.question}
                        answer={faq.answer}
                        showDivider={idx !== faqs.length - 1}
                        showOptions={isAuthorized?.roles?.includes(APP_ROLES.ADMIN)}
                        onDelete={() => getFaqs()}
                        onEdit={() => handleEdit(faq)}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateFaq
        isModalVisible={isModalCreateVisible}
        onCreateFaq={getFaqs}
        setIsModalVisible={setIsModalCreateVisible}
      />
      <EditFaq
        isModalVisible={isModalEditVisible}
        onCreateFaq={getFaqs}
        setIsModalVisible={setIsModalEditVisible}
        initFormValues={editModel}
      />
    </>
  )
}
