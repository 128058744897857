import {truncate} from 'app/shared/utils/utils'
import {useFormik} from 'formik'
import React, {FC, useEffect} from 'react'
import './BlogItem.scss'
import dayjs from 'dayjs'
import {TimeAgo} from 'app/shared/components/TimeAgo'

type BlogitemProps = {
  title: string
  description: string
  date: Date
  image: string
  author: string
  goTo: Function
  showEdit: boolean
  isPublished: boolean
  changeState: Function
}

const Blogitem: FC<BlogitemProps> = ({
  title,
  description,
  date,
  image,
  author,
  goTo,
  showEdit,
  isPublished,
  changeState,
}) => {
  return (
    <BlogPost
      title={title}
      description={description}
      date={date}
      image={image}
      author={author}
      goTo={goTo}
      showEdit={showEdit}
      isPublished={isPublished}
      changeState={changeState}
    />
  )
}

const BlogPost = ({
  title,
  description,
  date,
  image,
  author,
  goTo,
  showEdit,
  isPublished,
  changeState,
}: {
  title: string
  description: string
  date: Date
  image: string
  author: string
  goTo: Function
  showEdit: boolean
  isPublished: boolean
  changeState: Function
}) => {
  const fallbackImage = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg'

  const format = (date: Date) => {
    return dayjs(date).format('MMMM DD, YYYY')
  }

  const [check, setCheck] = React.useState(isPublished)

  useEffect(() => {
    setCheck(isPublished)
  }, [isPublished])

  const formik = useFormik({
    initialValues: {
      publichecked: check,
    },
    onSubmit: (values) => {
      changeState(values.publichecked)
    },
  })

  return (
    <article className='card card-hover' style={{maxWidth: '35rem', height: '500px'}}>
      <div className='position-relative'>
        <img
          src={image}
          onError={(e) => (e.currentTarget.src = fallbackImage)}
          alt='Espacio de trabajo con laptop y café'
          className='card-img-top'
          style={{height: '20rem', objectFit: 'cover'}}
        />
      </div>

      <div
        className='card-body'
        style={{
          overflow: 'hidden', 
          boxSizing: 'border-box', 
        }}
      >
        <div
          style={{
            height: '120px',
            overflow: 'scroll',
            textOverflow: 'ellipsis', 
          }}
        >
          <h2 className='card-title h4 fw-bold mb-3'>{truncate(title, 90)}</h2>

          <p className='card-text text-muted mb-3 fs-base'>{truncate(description, 100)}</p>
        </div>

        <div className='d-flex gap-4 mb-3 meta-info'>
          <div className='meta-item'>
            <i className='far fa-user me-2'></i>
            <span>{author}</span>
          </div>
          <div className='meta-item'>
            <i className='far fa-calendar me-2'></i>
            <span>{format(date)}</span>
          </div>
          <div className='meta-item'>
            <i className='far fa-clock me-2'></i>
            <span>
              <TimeAgo time={date} />
            </span>
          </div>
        </div>

        <button
          className='btn btn-link read-more p-0 d-inline-flex align-items-center gap-2'
          onClick={() => goTo()}
        >
          Leer más
          <i className='fas fa-long-arrow-alt-right'></i>
        </button>
        {showEdit && (
          <div
            className='form-check form-switch form-check-custom form-check-solid float-end mt-2 position-absolute switch'
            style={{left: '88%'}}
          >
            <input
              checked={formik.values.publichecked}
              className='form-check-input'
              type='checkbox'
              id='flexSwitchDefault'
              onChange={(e: any) => {
                formik.setFieldValue('publichecked', e.target.checked)
                formik.handleSubmit()
              }}
            />
            <label className='form-check-label' htmlFor='flexSwitchDefault'></label>
          </div>
        )}
      </div>
    </article>
  )
}

export default Blogitem
