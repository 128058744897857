/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UserModel } from '../../../../app/modules/auth/models/UserModel'
import { RootState } from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import { useDispatch } from 'react-redux'
import { KTSVG } from '../../../helpers'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { APP_ROLES } from 'setup/app-constants'
import { showModal } from 'app/modules/jobs/actions'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
    navigate('/', { replace: true })
  }

  const handleShowModal = () => {
    dispatch(showModal());
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant='primary' id='dropdown-basic'>
          <KTSVG path='/media/icons/duotune/communication/com013.svg' className='svg-icon-2 me-2' />
          {user.name}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              navigate('/user/profile/jobs')
            }}
          >
            Mi Perfil
          </Dropdown.Item>
          <Dropdown.Item onClick={handleShowModal}>
            Publicar una vacante
          </Dropdown.Item>
          {user?.roles?.includes(APP_ROLES.ADMIN) && (
            <Dropdown.Item onClick={() => {
              navigate('/admin/jobManagement')
            }}>
              Gestión de vacantes
            </Dropdown.Item>
          )}
          <div className='separator my-10'></div>
          <Dropdown.Item onClick={logout}>
            <KTSVG path='/media/icons/duotune/arrows/arr076.svg' className='svg-icon-2 me-2' />
            Cerrar sesión
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export { HeaderUserMenu }
