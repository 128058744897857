import React, { FC } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Button } from 'antd'
import clsx from 'clsx'

const contactSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(50, 'Maximo 50 caracteres')
        .required('El nombre es requerido'),
    email: Yup.string().email('El correo es invalido')
        .required('El correo es requerido'),
    phone: Yup.string()
        .min(10, 'Minimo 3 caracteres')
        .max(12, 'Maximo 50 caracteres'),
    subject: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(150, 'Maximo 50 caracteres')
        .required('El asunto es requerido'),
    message: Yup.string()
        .min(3, 'Minimo 3 caracteres')
        .max(3000, 'Maximo 50 caracteres')
        .required('El mensaje es requerido'),
})

type Props = {
    isModalVisible: boolean,
    values?: any
    setIsModalVisible: (isModalVisible: boolean) => void
    onCreateCategory: (values: any) => void
}

const initialValues = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
}

export const ContactModal: FC<Props> = ({
    isModalVisible,
    setIsModalVisible,
    onCreateCategory,
    values
}) => {
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (values) {
            formik.setValues({ ...formik.values, ...values })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values])


    const formik = useFormik({
        initialValues,
        validationSchema: contactSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            onCreateCategory(values);
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        },
    })

    return (
        <>
            <Modal
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                okText='Editar'
                cancelText='Cancelar'
                destroyOnClose={true}
                footer={[
                    <Button key='back' onClick={() => {
                        setIsModalVisible(false)
                        formik.setValues({ ...initialValues })
                    }}>
                        Cancelar
                    </Button>,
                    <Button key='submit' type='primary' loading={loading} onClick={() => formik.handleSubmit()}>
                        Enviar
                    </Button>,
                ]}
            >
                <form action="" className="form mb-15" method="post" id="kt_contact_form">
                    <h1 className="fw-bolder text-dark mb-9">Envíenos un correo electrónico</h1>
                    <div className="row mb-5">
                        <div className="col-md-12 fv-row">
                            <label className="fs-5 fw-bold mb-2 required">Nombre</label>
                            <input
                                type="text"
                                placeholder=""
                                autoComplete='off'
                                {...formik.getFieldProps('name')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.name && formik.errors.name,
                                    },
                                    {
                                        'is-valid': formik.touched.name && !formik.errors.name,
                                    }
                                )}
                            />
                            {formik.touched.name && formik.errors.name && (
                                <span className='text-danger'>{formik.errors.name}</span>
                            )}
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-12 fv-row">
                            <label className="fs-5 fw-bold mb-2 required">Correo de contacto</label>
                            <input
                                type="email"
                                placeholder=""
                                autoComplete='off'
                                {...formik.getFieldProps('email')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.email && formik.errors.email,
                                    },
                                    {
                                        'is-valid': formik.touched.email && !formik.errors.email,
                                    }
                                )}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <span className='text-danger'>{formik.errors.email}</span>
                            )}
                        </div>
                        <div className="col-md-12 fv-row mt-5">
                            <label className="fs-5 fw-bold mb-2">Telefono de contacto</label>
                            <input
                                type="phone"
                                placeholder=""
                                autoComplete='off'
                                {...formik.getFieldProps('phone')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.phone && formik.errors.phone,
                                    },
                                    {
                                        'is-valid': formik.touched.phone && !formik.errors.phone,
                                    }
                                )}
                            />
                            {formik.touched.phone && formik.errors.phone && (
                                <span className='text-danger'>{formik.errors.phone}</span>
                            )}
                        </div>
                    </div>
                    <div className="d-flex flex-column mb-5 fv-row">
                        <label className="fs-5 fw-bold mb-2 required">Asunto</label>
                        <input
                            placeholder=""
                            autoComplete='off'
                            {...formik.getFieldProps('subject')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.subject && formik.errors.subject,
                                },
                                {
                                    'is-valid': formik.touched.subject && !formik.errors.subject,
                                }
                            )}
                        />
                        {formik.touched.subject && formik.errors.subject && (
                            <span className='text-danger'>{formik.errors.subject}</span>
                        )}
                    </div>
                    <div className="d-flex flex-column mb-10 fv-row">
                        <label className="fs-6 fw-bold mb-2 required">Mensage</label>
                        <textarea
                            rows={6}
                            placeholder=""
                            autoComplete='off'
                            {...formik.getFieldProps('message')}
                            className={clsx(
                                'form-control form-control-lg form-control-solid',
                                {
                                    'is-invalid': formik.touched.message && formik.errors.message,
                                },
                                {
                                    'is-valid': formik.touched.message && !formik.errors.message,
                                }
                            )}
                        />
                        {formik.touched.message && formik.errors.message && (
                            <span className='text-danger'>{formik.errors.message}</span>
                        )}
                    </div>
                </form>
            </Modal>
        </>

    )
}
