export enum LinkType {
    Facebook = 1,
    Instagram = 2,
    Twitter = 3,
    LinkedIn = 4,
    Youtube = 5,
    Tiktok = 6,
    Snapchat = 7,
    Pinterest = 8,
    Webside = 9,
    Email = 10,
    Phone = 11,
}