import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import jobReducer from 'app/modules/jobs/JobRedux';

export const SHOW_ADD_JOB = 'SHOW_ADD_JOB';
export const HIDE_ADD_JOB = 'HIDE_ADD_JOB';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  job: jobReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
