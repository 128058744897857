import React from 'react'
import { ServiceCard } from './components/ServiceCard'

export const ServicesPage = () => {
    const servicesList = [
        {
            title: 'Básico',
            price: "500",
            subtitle: 'Ideal cuando necesitas difusion de tu vacante en las redes sociales',
            features: [
                {
                    name: 'Publicacion en Instagram',
                    enable: true
                },
                {
                    name: 'Publicacion en Facebook',
                    enable: true
                },
                {
                    name: 'Publicacion en Linkedin',
                    enable: true
                },
                {
                    name: 'Publicacion en Nuestro Sitio Web',
                    enable: true
                },
                {
                    name: 'Envio de terna de perfiles',
                    enable: false
                },
                {
                    name: 'Realizacion de Pre-Entrevista',
                    enable: false
                },
                {
                    name: 'Pruebas Psicométricas',
                    enable: false
                },
                {
                    name: 'Envio de oferta laboral',
                    enable: false
                },
                {
                    name: 'Otros',
                    enable: false
                },
            ]
        },
        {
            title: 'Medio',
            price: "1000 x terna",
            subtitle: 'Ideal cuando necesitas perfiles de manera urgente',
            features: [
                {
                    name: 'Publicacion en Instagram',
                    enable: true
                },
                {
                    name: 'Publicacion en Facebook',
                    enable: true
                },
                {
                    name: 'Publicacion en Linkedin',
                    enable: true
                },
                {
                    name: 'Publicacion en Nuestro Sitio Web',
                    enable: true
                },
                {
                    name: 'Envio de terna de perfiles',
                    enable: true
                },
                {
                    name: 'Realizacion de Pre-Entrevista',
                    enable: false
                },
                {
                    name: 'Pruebas Psicométricas',
                    enable: false
                },
                {
                    name: 'Envio de oferta laboral',
                    enable: false
                },
                {
                    name: 'Otros',
                    enable: false
                },
            ]
        },
        {
            title: 'Avanzado',
            price: "1000 x perfil",
            subtitle: 'Ideal cuando necesitas filtro de los perfiles ideales',
            features: [
                {
                    name: 'Publicacion en Instagram',
                    enable: true
                },
                {
                    name: 'Publicacion en Facebook',
                    enable: true
                },
                {
                    name: 'Publicacion en Linkedin',
                    enable: true
                },
                {
                    name: 'Publicacion en Nuestro Sitio Web',
                    enable: true
                },
                {
                    name: 'Envio de terna de perfiles',
                    enable: true
                },
                {
                    name: 'Realizacion de Pre-Entrevista',
                    enable: true
                },
                {
                    name: 'Pruebas Psicométricas',
                    enable: false
                },
                {
                    name: 'Envio de oferta laboral',
                    enable: false
                },
                {
                    name: 'Otros',
                    enable: false
                },
            ]
        },
        {
            title: 'Empresarial',
            price: "XXX",
            subtitle: 'Ideal cuando necesitas un acompañamiento en todo el proceso',
            features: [
                {
                    name: 'Publicacion en Instagram',
                    enable: true
                },
                {
                    name: 'Publicacion en Facebook',
                    enable: true
                },
                {
                    name: 'Publicacion en Linkedin',
                    enable: true
                },
                {
                    name: 'Publicacion en Nuestro Sitio Web',
                    enable: true
                },
                {
                    name: 'Envio de terna de perfiles',
                    enable: true
                },
                {
                    name: 'Realizacion de Pre-Entrevista',
                    enable: true
                },
                {
                    name: 'Pruebas Psicométricas',
                    enable: true
                },
                {
                    name: 'Envio de oferta laboral',
                    enable: true
                },
                {
                    name: 'Otros',
                    enable: true
                },
            ]
        }
    ]

    return (
        <div className="content flex-row-fluid shadow">
            <div className="card">
                <div className="card-body p-lg-17">
                    <div className="d-flex flex-column">
                        <div className="mb-13 text-center">
                            <h1 className="fs-2hx fw-bolder mb-5">Elige tu plan</h1>
                        </div>
                        <div className="row g-10">
                            {servicesList.length && servicesList.map(service => (
                                <div className="col-xl-4">
                                    <ServiceCard title={service.title} subtitle={service.subtitle} price={service.price}
                                        features={service.features} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
