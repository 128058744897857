/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Field, ErrorMessage, useField} from 'formik'
import {
  CategoriesControllerServiceProxy,
  HireTypeControllerServiceProxy,
  LocationsControllerServiceProxy,
} from 'app/services/service-proxies/service-proxies'
import axios from 'axios'
import CurrencyInput from 'react-currency-input-field'
import {UserModel} from 'app/modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from 'setup/redux/RootReducer'
import {APP_ROLES} from 'setup/app-constants'
import clsx from 'clsx'
import {createWorker} from 'tesseract.js'
import {Select} from 'antd'

type Props = {
  companies: any[]
}

const JobStep3: FC<Props> = ({companies}) => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const [categories, setCategories] = React.useState<any[]>([])
  const [locations, setLocation] = React.useState<any[]>([])
  const [hyreTypes, setHyreTypes] = React.useState<any[]>([])
  const [isDefaultCompany, setIsDefaultCompany] = React.useState<Boolean>(false)
  const [field, meta, helpers] = useField('salary')

  const [fieldSalary, metaSalary, helpersSalary] = useField('isRemote')
  const [fieldImage] = useField('image')

  const [fieldContactMethod, metafieldContactMethod, helpersfieldContactMethod] =
    useField('contactMethod')
  const [fieldCompanyId] = useField('companyId')
  const [fieldEmail, metaEmail, helpersEmail] = useField('emails')

  React.useEffect(() => {
    const categoryClient = new CategoriesControllerServiceProxy(undefined, axios)
    const locationClient = new LocationsControllerServiceProxy(undefined, axios)
    const hyreTypeClient = new HireTypeControllerServiceProxy(undefined, axios)

    locationClient.getAllWithoutPagination().then((locations) => {
      setLocation(locations.data)
    })

    categoryClient.getAllWithoutPagination().then((res) => {
      setCategories(res.data)
    })

    hyreTypeClient.getAllWithoutPagination().then((res) => {
      setHyreTypes(res.data)
    })
  }, [])
  React.useEffect(() => {
    if (fieldCompanyId.value) {
      const company = companies.find((e) => e.default)
      setIsDefaultCompany(company._id === fieldCompanyId.value)
    }
  }, [fieldCompanyId.value, companies])

  React.useEffect(() => {
    if (fieldImage.value) {
      recognize(fieldImage.value)
    }
  }, [fieldImage.value])

  const recognize = async (image: any) => {
    const worker = await createWorker()

    try {
      await worker.load()
      await worker.loadLanguage('eng+spa')
      await worker.initialize('eng+spa')

      const {
        data: {text},
      } = await worker.recognize(image)
      const regexEmail = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
      const emails: string[] = text.match(regexEmail) || []

      if (emails.length > 0) {
        helpersEmail.setValue(emails.join(','))
      }
    } catch (error) {
      console.error('Error al reconocer el texto:', error)
    } finally {
      await worker.terminate()
    }
  }

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Otros datos</h2>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Categoria</label>

        <Field name='category'>
          {({field, form, meta}: any) => {
            return (
              <Select
                className='search-select'
                showSearch
                placeholder='Selecciona una categoria'
                optionFilterProp='children'
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={categories.map((category) => ({
                  label: category.name,
                  value: category._id,
                }))}
              />
            )
          }}
        </Field>

        <ErrorMessage name='category'>
          {(msg) => <div style={{color: 'red'}}>{msg}</div>}
        </ErrorMessage>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center form-label'>
          <span className='required'>Locacion</span>
        </label>

        <Field name='location'>
          {({field, form, meta}: any) => {
            return (
              <Select
                className='search-select'
                showSearch
                placeholder='Selecciona una locacion'
                optionFilterProp='children'
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={locations.map((category) => ({
                  label: category.name,
                  value: category._id,
                }))}
              />
            )
          }}
        </Field>

        <ErrorMessage name='location'>
          {(msg) => <div style={{color: 'red'}}>{msg}</div>}
        </ErrorMessage>
      </div>

      <div className='fv-row mb-10'>
        <label className='form-label required'>Tipo de jornada</label>

        <Field name='hyreType'>
          {({field, form, meta}: any) => {
            return (
              <Select
                className='search-select'
                showSearch
                placeholder='Selecciona un tipo de jornada'
                optionFilterProp='children'
                value={field.value}
                onChange={(value) => form.setFieldValue(field.name, value)}
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={hyreTypes.map((category) => ({
                  label: category.name,
                  value: category._id,
                }))}
              />
            )
          }}
        </Field>

        <ErrorMessage name='hyreType'>
          {(msg) => <div style={{color: 'red'}}>{msg}</div>}
        </ErrorMessage>
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Salario (opcional)
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Tip: Aunque el salario no sea obligatorio, es una buena idea que lo pongas'
          ></i>
        </label>
        <CurrencyInput
          id='salary'
          name='salary'
          placeholder='0'
          defaultValue={0}
          value={field.value}
          decimalsLimit={2}
          allowNegativeValue={false}
          onValueChange={(value, name) => helpers.setValue(value)}
          className='form-control form-control-lg form-control-solid'
        />
        <ErrorMessage name='salary'>
          {(msg) => <div style={{color: 'red'}}>{msg}</div>}
        </ErrorMessage>
      </div>

      {(isDefaultCompany || isAuthorized?.roles?.includes(APP_ROLES.ADMIN)) && (
        <div className='mb-10 fv-row'>
          <label
            className={clsx('d-flex align-items-center form-label mb-3', {
              required: isDefaultCompany,
            })}
          >
            Correos
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Lista de correos separados por comas'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='emails'
            placeholder='Correo'
          />
          <ErrorMessage name='emails'>
            {(msg) => <div style={{color: 'red'}}>{msg}</div>}
          </ErrorMessage>
        </div>
      )}

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Telefono (opcional)
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Telefono de contacto'
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='phone'
          placeholder='Telefono'
        />
        <ErrorMessage name='phone'>{(msg) => <div style={{color: 'red'}}>{msg}</div>}</ErrorMessage>
      </div>

      <div className='mb-10 fv-row'>
        <label className='d-flex align-items-center form-label mb-3'>
          Enlace para aplicar (opcional)
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Enlace para aplicar a la vacante'
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='linkToApply'
          placeholder='Enlace para aplicar'
        />
        <ErrorMessage name='linkToApply'>
          {(msg) => <div style={{color: 'red'}}>{msg}</div>}
        </ErrorMessage>
      </div>

      <label className='d-flex align-items-center form-label mb-3'>
        Metodo de contacto
        <i
          className='fas fa-exclamation-circle ms-2 fs-7'
          data-bs-toggle='tooltip'
          title='Como deseas que te contacten'
        ></i>
      </label>
      <div className='d-flex fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid me-10'>
          <Field
            name='contactMethod'
            id='contactMethod_0'
            type='radio'
            className='form-check-input h-30px w-30px me-2'
            required
            value='EMAIL'
            onChange={() => {
              helpersfieldContactMethod.setValue('EMAIL')
            }}
          />
          <label htmlFor='contactMethod_0' className='custom-control-label'>
            Correo Electronico
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid me-10'>
          <Field
            name='contactMethod'
            id='contactMethod_1'
            type='radio'
            className='form-check-input h-30px w-30px me-2'
            required
            value='PHONE'
            onChange={() => {
              helpersfieldContactMethod.setValue('PHONE')
            }}
          />
          <label htmlFor='contactMethod_1' className='custom-control-label'>
            Telefono
          </label>
        </div>
        <div className='form-check form-check-custom form-check-solid me-10'>
          <Field
            name='contactMethod'
            id='contactMethod_1'
            type='radio'
            className='form-check-input h-30px w-30px me-2'
            required
            value='WEBSITE'
            onChange={() => {
              helpersfieldContactMethod.setValue('WEBSITE')
            }}
          />
          <label htmlFor='contactMethod_1' className='custom-control-label'>
            Pagina Web
          </label>
        </div>
      </div>
      <ErrorMessage name='contactMethod'>
        {(msg) => <div style={{color: 'red'}}>{msg}</div>}
      </ErrorMessage>
      <div className='fv-row mb-10'>
        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
          <input
            type='checkbox'
            className='form-check-input'
            defaultChecked={fieldSalary.value}
            onChange={({target}) => {
              helpersSalary.setValue(target.checked)
            }}
            name='isRemote'
          />
          <label className='form-check-label'>¿Es un puesto remoto?</label>
        </div>
      </div>
    </div>
  )
}

export {JobStep3}
