import React, { useState } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Modal, Button } from 'react-bootstrap'
import clsx from 'clsx'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { message } from 'antd'
import {
  FaqsControllerServiceProxy,
  CreateFaqDto,
} from 'app/services/service-proxies/service-proxies'
import { isContentEmpty } from 'app/shared/utils/utils'

const faqClient = new FaqsControllerServiceProxy(undefined, axios)

export interface ICreateFaq {
  _id: string | undefined
  question: string
  answer: string
}

type Props = {
  isModalVisible: boolean
  setIsModalVisible: (isModalVisible: boolean) => void
  onCreateFaq: () => void
}

const registrationSchema = Yup.object().shape({
  question: Yup.string()
    .min(3, 'Minimo 3 caracteres')
    .max(100, 'Maximo 50 caracteres')
    .required('La pregunta es requerida'),
  answer: Yup.string().min(3, 'Minimo 3 caracteres').required('La respuesta es requerida'),
})

export const CreateFaq: React.FC<Props> = ({ isModalVisible, setIsModalVisible, onCreateFaq }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)
  const [convertedText, setConvertedText] = useState<EditorState | undefined>(undefined)

  const closeModal = () => {
    formik.setValues({
      _id: undefined,
      question: '',
      answer: '',
    })
    setIsModalVisible(false)
  }

  const onEditorStateChange = (editorState: EditorState) => {
    setConvertedText(editorState)
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    if (isContentEmpty(html)) {
      formik.setValues({ ...formik.values, answer: '' })
      return
    }

    formik.setValues({ ...formik.values, answer: html })
  }

  const formik = useFormik({
    initialValues: {
      question: '',
      answer: '',
    } as ICreateFaq,
    validationSchema: registrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      let data: CreateFaqDto = new CreateFaqDto()
      data.init(values)
      data.question = values.question.charAt(0).toUpperCase() + values.question.slice(1)
      data.answer = values.answer
      faqClient
        .create(data)
        .then(() => {
          setLoading(false)
          closeModal()
          onCreateFaq()
          message.success({
            message: `Notification`,
            description: 'Pregunta creada con exito!!!',
            placement: 'topRight',
          })
        })
        .catch((err: any) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(err.message)
        })
    },
  })
  return (
    <Modal fullscreen show={isModalVisible} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Crear pregunta frecuente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
        >
          {/* begin::Form group name */}
          <div className='fv-row mb-7'>
            <label className='d-flex align-items-center form-label mb-5 required'>Pregunta</label>
            <input
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('question')}
              className={clsx(
                'form-control form-control-lg',
                {
                  'is-invalid': formik.touched.question && formik.errors.question,
                },
                {
                  'is-valid': formik.touched.question && !formik.errors.question,
                }
              )}
            />
            {formik.touched.question && formik.errors.question && (
              <div className='text-danger mt-2'>{formik.errors.question}</div>
            )}
          </div>
          {/* end::Form group */}
          <div className='mb-0 fv-row'>
            <label className='d-flex align-items-center form-label mb-5 required'>
              Respuesta
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Tip: Se lo más claro posible'
              ></i>
            </label>

            <div className='mb-0'>
              {formik.touched.answer && formik.errors.answer && (
                <div className='text-danger mb-2'>{formik.errors.answer}</div>
              )}
              <Editor
                editorStyle={{ border: '1px solid #E4E6EF', height: '800px' }}
                editorState={convertedText}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                onEditorStateChange={onEditorStateChange}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setIsModalVisible(false)}>
          Cerrar
        </Button>
        <Button variant='primary' onClick={() => formik.handleSubmit()}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
