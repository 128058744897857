/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {usePagination} from '../hooks'

type Props = {
  totalRecorsds: number
  pageSize: number
  currentPage: number
  showCount?: boolean
  onPageChange: (page: number) => void
}

export const Pagination: FC<Props> = ({
  totalRecorsds,
  pageSize,
  currentPage,
  showCount = true,
  onPageChange,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount: totalRecorsds,
    pageSize,
  })

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div>
      {showCount && (
        <span className='float-end me-5'>
          {pageSize * (currentPage - 1) + 1} - {pageSize * currentPage} de {totalRecorsds} registros
        </span>
      )}
      <ul className='pagination mb-5'>
        <li className={currentPage === 1 ? 'page-item previous disabled' : 'page-item previous'}>
          <a onClick={() => onPageChange(currentPage - 1)} className='page-link'>
            <i className='previous'></i>
          </a>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === '...') {
            return (
              <li key={index} className='page-item disabled'>
                &#8230;
              </li>
            )
          }
          return (
            <li
              key={index}
              className={pageNumber === currentPage ? 'page-item active' : 'page-item'}
            >
              <a className='page-link' onClick={() => onPageChange(pageNumber as number)}>
                {pageNumber}
              </a>
            </li>
          )
        })}
        <li className={currentPage === lastPage ? 'page-item next disabled' : 'page-item next'}>
          <a onClick={() => onPageChange(currentPage + 1)} className='page-link'>
            <i className='next'></i>
          </a>
        </li>
      </ul>
    </div>
  )
}
