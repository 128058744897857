import React from 'react'
import hf from 'app/services/IAService'

const MessageParser = ({children, actions}: any) => {
  const parse = async (text: any) => {
    const message = text.toLowerCase()
    if (message.includes('hola')) {
      actions.answer('Hola. Encantado de conocerlo.')
    } else if (message.includes('buscar') || message.includes('encontrar')) {
      actions.answer(
        'Puedes buscar empleos utilizando la barra de búsqueda en la página principal o explorando las diferentes categorías de trabajo en el menu "Encuentra una vacante".'
      )
    } else if (
      message.includes('enviar') ||
      message.includes('postular') ||
      message.includes('solicitar') ||
      message.includes('aplicar')
    ) {
      actions.answer(
        'Sí, puedes enviar tu currículum directamente desde esta página web ingresando a la vacante que te interesa, haciendo click en "Solicitar trabajo" y llenado los datos requeridos.'
      )
    } else if (message.includes('crear una cuenta') || message.includes('registrarse')) {
      actions.answer(
        'Para crear una cuenta de usuario, en la parte superior derecha de la página haz click en el boton "Inicia sesion" y luego en "Crea una cuenta" y completa el formulario de registro.'
      )
    } else if (message.includes('tener experiencia') || message.includes('experiencia')) {
      actions.answer(
        'Los requisitos de experiencia varían según cada trabajo publicado, pero muchos trabajos no requieren experiencia previa.'
      )
    } else if (message.includes('tener estudios') || message.includes('estudios')) {
      actions.answer(
        'Los requisitos de estudios varían según cada trabajo publicado, pero muchos trabajos no requieren estudios universitarios.'
      )
    } else if (message.includes('gracias') || message.includes('gracias')) {
      actions.answer('De nada. Estoy para servirle.')
    } else if (message.includes('adios') || message.includes('adios')) {
      actions.answer('Adiós. Espero haber sido de ayuda.')
    } else if (message.includes('ayuda') || message.includes('ayuda')) {
      actions.answer(
        'Puedo ayudarte a buscar empleos, enviar tu currículum, crear una cuenta de usuario, etc.'
      )
    } else if (message.includes('mismo tiempo')) {
      actions.answer('Sí, puedes aplicar a varios trabajos al mismo tiempo.')
    } else if (message.includes('mejorar mi currículum')) {
      actions.answer(
        'Puedes mejorar tu currículum asegurándote de que tenga un formato claro y fácil de leer, destacando tus habilidades y logros relevantes para el trabajo que estás buscando, y utilizando palabras clave que los empleadores buscan en los currículums. También puedes pedirle a alguien que revise tu currículum para asegurarte de que sea efectivo y esté libre de errores.'
      )
    } else if (message.includes('prepararme para una entrevista de trabajo')) {
      actions.answer(
        'Puedes prepararte para una entrevista de trabajo investigando sobre la empresa y la posición que estás solicitando, practicando preguntas de entrevista comunes y preparando tus respuestas, vistiendo de manera apropiada para la entrevista y asegurándote de llegar temprano para mostrar puntualidad.'
      )
    } else if (message.includes('costo por publicar') || message.includes('cual es el costo')) {
      actions.answer(
        'El costo de nuestros servicios esta en el menu "Servicios" en la parte superior de la página.'
      )
    } else if (message.includes('publicar una vacante') || message.includes('publicar')) {
      actions.answer(`Debes iniciar sesión en tu cuenta de usuario para poder publicar una vacante.
                            Si no tienes una cuenta, puedes crear una en la parte superior derecha de la página haciendo click en "Inicia sesión" y luego en "Crea una cuenta".
                            luego en el menu "Publicar una vacante" en la parte superior de la página y completa el formulario de registro. 
                            PD: El costo de nuestros servicios esta en el menu "Servicios" en la parte superior de la página.`)
    } else {
      try {
        const model = 'meta-llama/Meta-Llama-3-8B'
        const result = await hf.textGeneration({
          model,
          inputs: message,
        })
        if (result.generated_text) {
          actions.answer(result.generated_text)
        } else {
          actions.answer('Lo siento, no pude obtener una respuesta. Por favor, intente de nuevo.')
        }
      } catch (error: any) {
        actions.answer('Lo siento, no pude obtener una respuesta. Por favor, intente de nuevo.')
      }
    }
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        })
      })}
    </div>
  )
}

export default MessageParser
