import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }: any) => {
    const answer = (message: string) => {
        const botMessage = createChatBotMessage(message);

        setState((prev: any) => ({
            ...prev,
            messages: [...prev.messages, botMessage],
        }));
    };

    return (
        <div>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    actions: {
                        answer,
                    },
                });
            })}
        </div>
    );
};

export default ActionProvider;